export const BUYER = "buyer";
export const SELLER = "seller";
export const BOTH = "both";

export const girlsCategories = [
    {
        title: 'Clothing',
        links: [
            'Coats & Jackets',
            'Dresses',
            'Jeans',
            'Jumpers & Cardigans',
            'Matching sets',
            'Shorts',
            'Skirts',
            'Snow',
            'Swimwear',
            'Tops & T-shirts',
            'Trousers',
        ]
    },
    {
        title: 'Accessories',
        links: [
            'Accessories',
            'Belts',
            'Hats, Scarves & Gloves',
            'Shoes',
        ]
    },
    {
        title: 'Be inspired',
        links: [
            'Beach',
            'Christening',
            'Christmas',
            'Ski',
            'Special occasion',
            'Summer',
            'Wedding',
            'Winter',
        ]
    },
    {
        title: 'Matching Set',
        links: ['See All'],
    },
];

export const brandsData = [
    {
        title: 'Brands',
        links: [
            "Armani",
            "Baby Dior",
            "Bonpoint",
            "Burberry",
            "Caramel",
            "Chloe Kids",
            "Dolce Gabbana",
            "Fendi",
            "Golden Goose",
            "Gucci",
            "Jacadi",
            "La Coqueta",
            "Moncler",
            "Ralph Lauren",
            "Tartine et Chocolat",
            "All Brands"
        ]
    }
];

export const boysCategories = [
    {
        title: 'Clothing',
        links: [
            'Coats & Jackets',
            'Jeans',
            'Jumpers & Cardigans',
            'Matching sets',
            'Shorts',
            'Snow',
            'Swimwear',
            'Tops & T-shirts',
            'Trousers',
        ]
    },
    {
        title: 'Accessories',
        links: [
            'Belts',
            'Hats, Scarves & Gloves',
            'Shoes',

        ]
    },
    {
        title: 'Be inspired',
        links: [
            'Beach',
            'Christening',
            'Christmas',
            'Ski',
            'Special occasion',
            'Summer',
            'Wedding',
            'Winter',
        ]
    },
    {
        title: 'Matching Set',
        links: ['See All'],
    },
];

export const babyCategories = [
    {
        title: 'Gender',
        links: [
            'Baby boy',
            'Baby girl',
            'Unisex',
        ]
    },
    {
        title: 'Clothing',
        links: [
            'All-in-one',
            'Coats & Jackets',
            'Dresses',
            'Jumpers & Cardigans',
            'Leggings',
            'Matching sets',
            'Shorts',
            'Skirts',
            'Snow',
            'Swimwear',
            'Tops & T-shirts',
            'Trousers',
        ]
    },
    {
        title: 'Accessories',
        links: [
            'All-in-one',
            'Nursery Accessories',
            'Shoes',
        ]
    },
    {
        title: 'Be inspired',
        links: [
            'Beach',
            'Christening',
            'Christmas',
            'Ski',
            'Special occasion',
            'Summer',
            'Wedding',
            'Winter',
        ]
    },
    {
        title: 'Matching Set',
        links: ['See All'],
    },
];

export const commonDropDownData = [
    {
        title: 'Clothing',
        links: [
            'All-in-One',
            'Babygrow',
            'Blazer',
            'Shorts',
            'Bloomers',
            'Blouse',
            'Coat',
            'Snowsuit',
            'Dress',
            'Gilet',
            'Jacket',
            'Swimwear',
            'Knitwear',
            'Leggings',
            'Onesie',
            'Top',
            'Outfit',
            'Romper',
            'Shirt',
            'Trousers',
        ]
    },
    {
        title: 'Accessories',
        links: [
            'Nursery Accessories',
            'Scarf',
            'Socks / Tights',
            'Mittens',
            'Belt',
            'Hair Accessory',
            'Hat',
            'Shoes'
        ]
    },
    {
        title: 'Collections',
        links: [
            'Special Occasion',
            'Summer',
            'Winter',
            'Beach',
            'Christmas',
            'Christening',
            'Ski',
            'Wedding',
        ]
    },
    {
        title: 'Matching Set',
        links: ['See All'],
    },
];

export const categories = [
    {
        type: 'Girls',
        data: [...girlsCategories]
    },
    {
        type: 'Boys',
        data: [...boysCategories]
    },
    {
        type: 'Baby',
        data: [...babyCategories]
    },
   
];

export const shoeSizeData = `
<h5>Shoe Size Conversion</h5>
<ul>
<li>0.5 baby | EU 15 </li>
<li>1 baby | EU 16 </li>
<li>1.5 baby | EU 17 </li>
<li>2 baby | EU 18 </li>
<li>3 baby | EU 19 </li>
<li>4 baby | EU 20 </li>
<li>4.5 baby | EU 21 </li>
<li>5 baby | EU 22 </li>
<li>6 baby | EU 23 </li>
<li>7 child | EU 24 </li>
<li>7.5 child | EU 25 </li>
<li>8 child | EU 26 </li>
<li>8.5 child | EU 26 </li>
<li>9 child | EU 27 </li>
<li>10 child | EU 28 </li>
<li>11 child | EU 29 </li>
<li>11.5 child | EU 30 </li>
<li>12 child | EU 31 </li>
<li>12.5 child | EU 31 </li>
</ul>
`;

export const conditionData = `
<h5>Item Condition</h5>

<h6>*We cannot accept altered items*</h6>

<p><b>Unworn with tags</b> - The item has never been worn before, has no defects, no modifications or
alterations and has the original tag still attached.</p>

<p><b>Unworn without tags</b> - The item has never been worn, has no defects, no modifications or alterations but the original tag has been removed.</p>

<p><b>Excellent condition</b> - The item has been worn a maximum of twice, has no signs of wear and tear, no stains and looks brand new and unworn.</p>

<p><b>Very good condition</b> - The item has had gentle use, has been worn more than twice, shows little to no signs of defects/wear (see list below) and has been well-maintained.</p>

<p><b>Good Condition</b> - The item has been worn more than twice, shows some signs of wear (see list below) but has been well-maintained.</p>

<p>Defects may include:
Spot or sweat marks, holes, tears, piling, loose stitches, small snags, pulled threads, discoloration, missing parts (Belt, strap etc), Odour and signs of wear on soles of shoes</p>

<p>*If your item has any of the above please ensure you include this in description and imagery*</p>
`;

export const CarrierOptions = [
    { label: "Carrier 1", value: "c1" },
    { label: "Carrier 2", value: "c2" },
    { label: "Carrier 3", value: "c3" },
    { label: "Carrier 4", value: "c4" },
    { label: "Carrier 5", value: "c5" },
]