import React from 'react';
import classNames from 'classnames';
import { NamedLink } from '../../../../components';
import { categories, commonDropDownData,brandsData } from '../../../../util/constants';
import algoliaInsights from 'search-insights';
import css from './TopbarDesktop.module.css';

const DOLCE_GABBANA = "Dolce Gabbana";
const FILTER_VIEWED = "Filter Viewed";
algoliaInsights('init', {
  appId: process.env.REACT_APP_APPLICATION_ID,
  apiKey: process.env.REACT_APP_ALGOLIA_ADMIN_API_KEY,
});

  
  // Define the Category component
const Category = ({ title, links, index, type, redirect, currentUser }) => {

  const handleRedirectTosearch = (value) =>{

    // Add algolia events.
    algoliaInsights('clickedFilters', {
      userToken: (currentUser && currentUser.id) ? currentUser.id.uuid : 'anonymous-user',
      eventName: FILTER_VIEWED,
      index: process.env.REACT_APP_ALGOLIA_LISTING_INDEX,
      filters: [`${title}:${value}`]
    });

    switch(type){
      case "Brands":
        return redirect.push(value == "All Brands" ? '/search' : `/search?refinementList%5BpublicData.brand.label%5D%5B0%5D=${value}`);
      case "Girls":
        return redirect.push(`/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${title}&refinementList%5BpublicData.categoryLevel2.Label%5D%5B0%5D=${title} > ${value}&refinementList%5BpublicData.gender.label%5D%5B0%5D=Girl`);
      case "Boys":
        return redirect.push(`/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${title}&refinementList%5BpublicData.categoryLevel2.Label%5D%5B0%5D=${title} > ${value}&refinementList%5BpublicData.gender.label%5D%5B0%5D=Boy`);
      case "Baby":
        return redirect.push(`/search?refinementList%5BpublicData.categoryLevel1.Label%5D%5B0%5D=${title}&refinementList%5BpublicData.categoryLevel2.Label%5D%5B0%5D=${title} > ${value}&refinementList%5BpublicData.isBabyCategory%5D%5B0%5D=true`);
      default:
        return redirect.push("/search");
    }
  }

  return (
    <div className={classNames(index == 0 && css.LgRow, index == (commonDropDownData.length - 1) && css.lastItem, css.dropDownItems, type == "Brands" && css.brands)}>
      <h3>{title}</h3>
      <ul>
        {links.map((link, index) => {
          if (link == "See All") {
            return <NamedLink key={index} className={css.seeAllLink} name={"AlgoliaSearchPage"}>{link}</NamedLink>
          } else {
            return <li key={index} onClick={() => handleRedirectTosearch(link)}>{link == DOLCE_GABBANA ? link.split(' ').join(" & ") : link}</li>
          }
        })}
      </ul>
    </div>
  )
};

const CategoriesDropdownMenu = props => {
  const {
    type,
    listingCategories,
    redirect,
    currentUser
  } = props;

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }} className={classNames(type == "Brands" && css.brandsWrapper)}>
        {/* {categories.filter(cate => cate.type == type).map(cate => cate.data).flat().map((category, index) => (
          <Category key={index} title={category.title} links={category.links} index={index} />
        ))} */}
        {(type == "Brands" ? brandsData : commonDropDownData).map((category, index) => (
          <Category key={index} title={category.title} links={category.links} index={index} type={type} redirect={redirect} currentUser={currentUser} />
        ))}
      </div>
    </div>
  );
};


export default CategoriesDropdownMenu;
